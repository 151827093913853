<template>
  <div>
    <b-button
      variant="primary"
      @click="openWidget"
    >
      {{ $t('virtualGroups.view') }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'VirtualGroups',
  components: {
    BButton,
  },
  mounted() {
    this.openWidget()
  },
  methods: {
    openWidget() {
      // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({
        url: 'https://calendly.com/virtual-groups',
        prefill: {},
        utm: {},
      })
    },
  },
}
</script>

<style scoped>
</style>
